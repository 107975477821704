body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
}

#root > .container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

#root > .container > div:first-child {
  width: auto;
  flex-grow: 1;
}

#root > .container > div:last-child {
  width: 400px;
  overflow-y: auto;
}


/* Workaround to tab menu which can't be disabled */
#root > .container > div:last-child > div:first-child{
  display: none;
}
