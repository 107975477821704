.Map-marker {
  background: rgba(255,255,255,0.9);
  border: 2px solid black;
  font-size: 8pt;
  padding: 2px 5px;
  /* @include border-radius(10px); */
  /* @include text-shadow; */
  cursor: pointer;
  font-weight: bold;
}

